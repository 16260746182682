.location-content{
    background-color:  #e0e0e0;
    width: 100%;
    height: 700px;
    padding: 30px 0;
}
.subtitle-location {
    font-size: 24px;
    color: #00525c;
    text-align: center;
}

@media screen and (max-width: 968px) {
    .location-content{
      height: 800px;
    }
  }
  