.general-container-header {
  margin-bottom: 10px;
}

.info-top-header {
  background-color: #00525c;
  padding: 5px 20px;
  height: 50px;
  box-sizing: border-box;
}

.content-top-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.info-top-header p, .info-top-header a {
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.logo{
  width: 120px;
}
.menu ul {
  display: flex;
  padding: 0;
  margin-left: 50px;
}

.menu ul li {
  list-style: none;
  margin-right: 25px;
}

.menu ul li a {
  text-decoration: none;
  border-bottom: 1px solid #00525c;
  color: #000000;
  padding-bottom: 2px;
  transition: color 1s;
  font-size: 1.6rem;
}

.d-contact-none{
  display: none;
}

.mobile-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 30px;
  color: #a7a7a7;
}

.menu-item-contact{
  text-transform: uppercase;
  background-color: #00525c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.2rem;
}

@media screen and (max-width: 1240px) {
  .wpp-cellphone{
    display: none;
  }
  .cellphone-number{
    font-size: 18px !important;
  }
  .info-top-header {
    height: 90px;
  }
  .content-top-header{
    flex-direction: column;
  }
  .logo{
    width: 100px;
  }
  .menu {
    display: none;
  }

  .d-contact-none{
    display: block !important;
  }

  .mobile-menu-icon {
    display: block;
    margin-left: auto;
  }

  .mobile-menu-open {
    display: block;
    position: absolute;
    top: 130px;
    right: 40px;
    background-color: #00525c;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    z-index: 2;
    padding: 20px 0;
  }

  .mobile-menu-open ul {
    flex-direction: column;
    margin-left: 20px;
  }

  .mobile-menu-open li {
    margin-bottom: 10px;
  }

  .mobile-menu-open a {
    color: #fff !important;
    font-size: 20px !important;
  }

  .mobile-menu-open a:hover{
    color: #E0E0E0 !important;
  }

  .menu-item-contact{
    display: none;
  }
}
