.content-contact{
    background-color: #e0e0e0;
    padding: 30px 0;
}
.subtitle-contact{
    font-size: 24px;
    color: #00525c;
    text-align: center;
}

.input-container{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 30px;
}

textarea, input{
    font-family: 'Bahnschrift', sans-serif;
}

input[type="text"], input[type="email"], textarea{
    width: 600px;
    background-color: #00525c;
    border: none;
    box-shadow: none;
    outline: none;
    border-radius: 10px;
    padding: 20px;
    color: #ffffff;
    font-size: 24px;
    box-sizing: border-box;
}
textarea{
    height: 100px;
}
input::placeholder, textarea::placeholder {
    font-size: 24px;
    color:#ffffff;
}

input[type="button"]{
    border: 1px solid #00525c;
    padding: 10px;
    border-radius: 5px;
    width: 200px;
    background: transparent;
    color: #00525c;
    font-size: 24px;
    cursor: pointer;
    transition: background-color 1s;
}

input[type="button"]:hover{
    background-color: #00525c;
    color: #fff;
}

.success-sent-email{
    color: #00525c;
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
}

.error-sent-email{
    color: #ff0000;
    font-size: 24px;
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
}
@media screen and (max-width: 1200px) {
    input[type="text"], input[type="email"], textarea{
      width: 100%;
    }
  }
  