.row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 40px;
}

.box-differential {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 15px;
    cursor: pointer;
    

    img {
        cursor: pointer;
        position: relative;
    }

    &:hover {
        p {
            opacity: 1;
        }
    }

    p {
        position: absolute;
        left: 65%;
        width: 300px;
        border-radius: 10px;
        background-color: #00525c;
        color: white;
        text-align: center;
        padding: 10px;
        opacity: 0;
        transition: opacity 0.3s;
        font-weight: normal;
        font-size: 14px;
    }
}

.box-differential h2 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 18px;
}

.box-differential button {
    background-color: transparent;
    border: 1px solid #00525c;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    color: #00525c;
    box-shadow: none;
    border-radius: 10px;
    cursor: pointer;
}
.box-modal button{
    background-color: #fff;
    border: 1px solid #00525c;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    color: #00525c;
    box-shadow: none;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
}
@media screen and (max-width: 1200px) {
    .row {
        display: flex;
        flex-direction: column;
    }

    .box-differential {
        margin: 15px 0;
    }

    .box-differential {
        p {
            top: 45%;
            left: 0%;
        }
    }

    .box-differential h2 {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 16px;
    }
}
