
.evolution-general-content{
    background-color:  #e0e0e0;
    width: 100%;
    padding: 30px 0;
}

.content-evolution{
    display: flex;
    gap: 20px;
    margin: 0 auto;
    justify-content: center;
}

.second-content-work{
    margin-top: 50px;
}
.content-work, .second-content-work{
    text-align: center;
}
.content-work span, .second-content-work span{
    font-size: 14px;
}

.content-work p, .second-content-work p{
    font-weight: bold;
    font-size: 24px;
}

.work{

    &.percentage-0 {
        background: linear-gradient(to right, #428A3C 0%, transparent 0%);
      }
    &.percentage-5 {
        background: linear-gradient(to right, #428A3C 10%, transparent 5%);
    }

    &.percentage-10 {
        background: linear-gradient(to right, #428A3C 10%, transparent 10%);
    }
    
    &.percentage-20 {
        background: linear-gradient(to right, #428A3C 20%, transparent 20%);
    }

    &.percentage-100 {
        background: linear-gradient(to right, #428A3C 20%, #428A3C 100%);
    }

    border: 5px solid #00525C;
    padding: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.second-work{

    &.percentage-0 {
        background: linear-gradient(to right, #428A3C 0%, transparent 0%);
      }
    
    &.percentage-10 {
        background: linear-gradient(to right, #428A3C 10%, transparent 10%);
    }
    
      &.percentage-22 {
        background: linear-gradient(to right, #428A3C 20%, transparent 22%);
    }

    border: 5px solid #00525C;
    padding: 10px;
    display: flex;
    width: 450px;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
}

@media screen and (max-width: 767px) {
    .content-evolution{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .second-work{
        width: 70%;
        margin: 10px auto;
    }
}