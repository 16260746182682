.banner {
  width: 100%;
  height: 35vw; /* 50% da largura da viewport (visualização da tela) */
  background: url("../../assets/banner-principal-new.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 767px) {
  .banner {
    height: 35vw; /* Reduza a altura para telas menores */
  }
}
