.datasheet-content{
    padding: 30px 0;
}
.datasheet{
    font-size: 20px;
    color: #00525c;
}

.sub-menu{
    margin-left: 20px;
}