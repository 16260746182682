.slider-images-general-container {
  background-color: #e0e0e0;
  width: 100%;
  height: fit-content;
  padding: 40px 0;
  box-sizing: border-box;
}
.close-modal{
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}
.container-slider {
  position: relative;

  img {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
  }
  .prev-slider {
    position: absolute;
    left: 0;
    top: 45%;
    padding: 20px;
    box-shadow: 7px -7px 0 1px #006689 inset;
    border: solid transparent;
    border-width: 0 0 2px 2px;
    transform: rotate(45deg);
    cursor: pointer;
  }

  .next-slider {
    position: absolute;
    right: 0;
    top: 45%;
    padding: 20px;
    box-shadow: 7px -7px 0 1px #006689 inset;
    border: solid transparent;
    border-width: 0 0 2px 2px;
    transform: rotate(225deg);
    cursor: pointer;
  }
}
.legend-image{
    width: fit-content;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin: 30px auto;
}

.content-legend-slider {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

@media screen and (max-width: 767px){
  .container-slider {
  
    .prev-slider {
      top: 35%;
      padding: 15px;
    }
  
    .next-slider {
      top: 35%;
      padding: 15px;
    }
  }
  
}
