@font-face {
  font-family: 'Bahnschrift';
  src: url(../src/fonts/bahnschrift-font.TTF)
}

*{
  margin: 0;
  padding: 0;
}

body{
  font-family: 'Bahnschrift', 'sans-serif';
}

.container{
  max-width: 1400px;
  padding: 0 20px;
  margin: 0 auto;
}
.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out; /* Ajuste a duração e a curva de tempo conforme necessário */
}

/* Defina a opacidade inicial para elementos com a classe "fade-in" */
.fade-in:not(.isVisible) {
  opacity: 0;
}

@keyframes slide-top {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animation-side {
  opacity: 1;
  -webkit-animation: slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-top 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}