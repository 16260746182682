.infos-content{
    background-color: #00525c;
    padding: 40px 0;
}

.info-address{
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 15px 0;
}
.info-address p, .info-address a {
    font-size: 24px;
    color: #fff;
    text-decoration: none;
}

@media screen and (max-width: 767px) {
    .info-address img{
        width: 45px;
        height: 40px;
    }
    .info-address p, .info-address a {
        font-size: 16px;
    }
}