.title-section {
    color: #00525c;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 32px;
}

.title-section::after {
    content: "";
    height: 3px;
    width: 50px;
    background-color: #00525c;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
}

.title-section-white{
    color: #ffffff;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 32px;
}


.title-section-white::after {
    content: "";
    height: 3px;
    width: 50px;
    background-color: #ffffff;
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
}