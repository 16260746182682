.about-content{
    padding: 30px 0;
    background-color: #e0e0e0;
    margin-top: 0;
    margin-bottom: 20px;
}

.text-about {
    font-size: 18px;
    line-height: 26px;
    color: #00525c;
    margin: 15px 0;
    text-align: justify;
}

.residencial-title{
    font-size: 32px;
    color: #00525c;
    border-bottom: 1px solid #00525c;
    margin: 15px 0;
    width: fit-content;
}
.general-container-about{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 30px;
}
.image-about{
    display: flex;
    justify-content: flex-end;
}
.image-about img{
    max-width: 100%;
    margin-top: 20px;
}

.photo-about{
    height: 600px;
}

@media screen and (max-width: 1280px) {
    .image-about{
        display: flex;
        justify-content: center;
    }
    .photo-about{
        height: auto;
    }
}
@media screen and (max-width: 767px) {

  
    .text-about {
        font-size: 17px;
    }

    .residencial-title{
        font-size: 28px;
    }
    .general-container-about{
        display: flex;
        flex-direction: column;
    }
}